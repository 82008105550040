<template>
  <div class="mx-2 report">
    <div class="container noPrint">
      <h1 class="my-3" v-if="!userIdProp">Payroll Reports</h1>
      <div class="row filter pt-3">
        <div class="col-12">
          <div>
            <div class="row" v-if="!userIdProp">
              <div class="col-12 col-md-6 col-lg-3">
                <label class="col-12 font-weight-bold pt-2 pl-0"
                  >Providers/Admins</label
                >
                <alert
                  v-if="usersWithRolesLoading"
                  class="py-8-px light-shadow"
                ></alert>
                <select
                  @change="
                    (filter.filterIsDirty = true),
                      (filter.providersManager = '')
                  "
                  v-model="filter.providers"
                  v-if="!usersWithRolesLoading"
                  class="form-control light-shadow"
                >
                  <option value="">All Providers/Admins</option>
                  <option
                    v-for="p in sortAlphabetically(providersAdminsList)"
                    :key="p.user_id"
                    :value="p.user_id"
                  >
                    {{ p.name }}
                  </option>
                </select>
              </div>
              <div class="col-12 col-md-6 col-lg-3 mt-3 mt-md-0">
                <label class="col-12 font-weight-bold pt-2 pl-0"
                  >Providers Administrator</label
                >
                <alert
                  v-if="usersWithRolesLoading || gettingProvidersManagers"
                  class="py-8-px light-shadow"
                ></alert>
                <select
                  @change="
                    (filter.filterIsDirty = true), (filter.providers = '')
                  "
                  v-model="filter.providersManager"
                  v-if="!usersWithRolesLoading && !gettingProvidersManagers"
                  class="form-control light-shadow"
                >
                  <option value="">All Administrators</option>
                  <option
                    v-for="admin in sortAlphabetically(providersManagers)"
                    :key="admin.user_id"
                    :value="admin.user_id"
                  >
                    {{ admin.name }}
                  </option>
                </select>
              </div>
              <div class="col-12 col-md-6 col-lg-3 mt-3 mt-md-0">
                <label class="col-12 font-weight-bold pt-2 pl-0">Class</label>
                <alert
                  v-if="gettingClasses"
                  class="py-8-px light-shadow"
                ></alert>
                <div class="form-group class-ddl mb-0" v-if="!gettingClasses">
                  <multiselect
                    v-model="filter.classification"
                    :options="classifications"
                    @input="filter.filterIsDirty = true"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="All Classes"
                    Value="id"
                    label="subject"
                    track-by="id"
                    :preselect-first="false"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-3 mt-3 mt-md-0">
                <label class="col-12 font-weight-bold pt-2 pl-0">Service</label>
                <alert
                  v-if="gettingServices"
                  class="py-8-px light-shadow"
                ></alert>
                <div class="form-group service-ddl" v-if="!gettingServices">
                  <multiselect
                    v-model="filter.service"
                    :options="mainServices"
                    @input="filter.filterIsDirty = true"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="All Services"
                    Value="id"
                    label="name"
                    track-by="id"
                    :preselect-first="false"
                  >
                  </multiselect>
                </div>
              </div>
            </div>
            <br v-if="!userIdProp" />

            <div class="row border-bottom align-items-center">
              <label class="col-12 col-md-1 font-weight-bold py-2 mb-2"
                >Date</label
              >
              <div class="mt-2 pt-1 col-12 col-md-6">
                <v-date-picker
                  v-show="filter.dateRangeEnabled"
                  v-model="filter.range"
                  mode="date"
                  :masks="masks"
                  is-range
                  @input="filter.filterIsDirty = true"
                >
                  <template v-slot="{ inputValue, inputEvents, isDragging }">
                    <div
                      class="
                        d-flex
                        justify-content-start
                        align-items-center
                        form-group
                        mb-4
                      "
                    >
                      <input
                        class="px-2 py-1 form-control light-shadow"
                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                        :value="inputValue.start"
                        v-on="inputEvents.start"
                      />
                      <div class="mx-2 mx-md-4">-</div>
                      <input
                        class="px-2 py-1 form-control light-shadow"
                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                        :value="inputValue.end"
                        v-on="inputEvents.end"
                      />
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>

            <br />
            <div class="row border-bottom">
              <div class="col-12 mt-2 pt-1 row mx-0">
                <select-control
                  v-model="uiFilter.optional_columns.group_by_criteria"
                  :options="
                    userIdProp
                      ? uiFilter.nonMainGroupByOptions
                      : uiFilter.groupByOptions
                  "
                  type="horizontal"
                  @input="shouldViewReport && getReportByFilter()"
                  class="group-by-select"
                  WrapClass="col-auto"
                  labelClass="d-flex align-items-center"
                  >Show and group by</select-control
                >
                <select-control
                  v-model="filter.non_billable"
                  :options="uiFilter.billableOptions"
                  @input="filter.filterIsDirty = true"
                  type="horizontal"
                  class="group-by-select ml-5"
                  WrapClass="col-auto"
                  labelClass="d-flex align-items-center"
                  >Session Type</select-control
                >
              </div>

              <div class="col-11 d-flex flex-wrap mt-2 py-4 row mx-0">
                <div class="custom-control custom-checkbox mr-md-5">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="apply_pagination"
                    v-model="filter.pagination"
                    @input="
                      (filter.filterIsDirty = true),
                        (uiFilter.optional_columns.automaticLoading =
                          !filter.pagination)
                    "
                  />
                  <label
                    class="custom-control-label mr-3 mb-3"
                    for="apply_pagination"
                    >Use Pagination</label
                  >
                </div>
                <div
                  class="custom-control custom-checkbox mr-md-5"
                  v-if="filter.pagination"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="automatic_loading"
                    v-model="uiFilter.optional_columns.automaticLoading"
                    @input="filter.filterIsDirty = true"
                  />
                  <label
                    class="custom-control-label mr-3 mb-3"
                    for="automatic_loading"
                    >Auto Loading</label
                  >
                </div>
                <div class="custom-control custom-checkbox mr-md-5">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_mileage"
                    v-model="uiFilter.optional_columns.mileage"
                  />
                  <label
                    class="custom-control-label mr-3 mb-3"
                    for="show_mileage"
                    >View QTY as hours, flat, and mileage</label
                  >
                </div>
                <div class="custom-control custom-checkbox mr-md-5">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_hst"
                    v-model="uiFilter.optional_columns.hst"
                  />
                  <label class="custom-control-label mr-3 mb-3" for="show_hst"
                    >Show HST</label
                  >
                </div>
                <div
                  class="custom-control custom-checkbox mr-md-5"
                  v-if="
                    authUser && (authUser.isAdmin || authUser.isManagingAdmin)
                  "
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_user_id"
                    v-model="uiFilter.optional_columns.userId"
                  />
                  <label
                    class="custom-control-label mr-3 mb-3"
                    for="show_user_id"
                    >Show
                    {{
                      userIdProp
                        ? isAdminProp
                          ? "Admin"
                          : "Provider"
                        : "Admin/Provider"
                    }}
                    ID</label
                  >
                </div>
                <div class="custom-control custom-checkbox mr-md-5">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_amt"
                    v-model="uiFilter.optional_columns.amt"
                  />
                  <label class="custom-control-label mr-3 mb-3" for="show_amt"
                    >Show Amount</label
                  >
                </div>
                <div class="custom-control custom-checkbox mr-md-5">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_tax"
                    v-model="uiFilter.optional_columns.tax"
                  />
                  <label class="custom-control-label mr-3 mb-3" for="show_tax"
                    >Show Tax</label
                  >
                </div>
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_sub"
                    v-model="uiFilter.optional_columns.subTotal"
                  />
                  <label class="custom-control-label mr-3 mb-3" for="show_sub"
                    >Show Subtotal</label
                  >
                </div>
              </div>
            </div>
            <div
              class="
                my-4
                d-flex
                justify-content-between
                flex-wrap
                report-actions-container
              "
            >
              <div
                class="
                  d-flex
                  flex-wrap
                  align-items-center
                  report-actions-inner-container
                "
              >
                <button
                  class="
                    mr-3
                    btn btn-black
                    text-uppercase
                    view-report
                    position-relative
                  "
                  :saving="saving"
                  :disabled="reportsLoading || busy"
                  @click="
                    (reportIsPending = reportIsPending || busy),
                      (isCancelled = isCancelled || busy),
                      getReportByFilterHelper()
                  "
                >
                  View Report
                  <div
                    class="action-indicator btn btn-danger spinner-grow"
                    v-if="filter.filterIsDirty"
                  ></div>
                </button>
                <div class="d-flex mr-3" :class="busy ? 'disabled' : ''">
                  <JsonExcel
                    :data="printExcelData"
                    name="CRM-Payroll-Report"
                    worksheet="Payroll Report"
                    :fields="excel_fields"
                    :before-generate="generateExcelOnly"
                    default-value=""
                    header="Payroll Report"
                  >
                    <button
                      class="
                        btn btn-deep-success
                        text-uppercase
                        position-relative
                      "
                      :disabled="reportsLoading || busy"
                    >
                      <i class="far fa-file-excel fa-lg"></i> Generate Excel
                      Only
                      <div
                        class="action-indicator btn btn-danger spinner-grow"
                        v-if="filter.filterIsDirty"
                      ></div>
                    </button>
                  </JsonExcel>
                  <div
                    class="btn-group excel-control-group"
                    :class="`${
                      uiFilter.optional_columns.group_by_criteria != 0
                        ? ''
                        : 'transparent'
                    }`"
                  >
                    <button
                      type="button"
                      class="btn p-1"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="fas fa-cog"></i>
                    </button>
                    <div
                      class="dropdown-menu print-menu excel-menu"
                      @click="$event.stopPropagation()"
                    >
                      <h3 class="mx-3 my-2">
                        <i class="fas fa-cog mr-2"></i>Excel settings
                      </h3>
                      <div class="dropdown-divider"></div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            :key="printSettings.exportTotalOnly"
                            type="checkbox"
                            class="custom-control-input"
                            id="show_total_only_in_report"
                            v-model="printSettings.exportTotalOnly"
                            @change="
                              shouldViewReport && getReportByFilterHelper()
                            "
                          />
                          <label
                            class="custom-control-label"
                            for="show_total_only_in_report"
                            >Export Total Only</label
                          >
                        </div>
                      </div>
                      <div
                        :class="`dropdown-item ${
                          printSettings.exportTotalOnly ? '' : 'disabled'
                        }`"
                        v-if="uiFilter.optional_columns.group_by_criteria == 2"
                      >
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="separate_rate_in_report"
                            :key="printSettings.separateRate"
                            v-model="printSettings.separateRate"
                            @change="
                              (printSettings.exportTotalOnly = true),
                                shouldViewReport && getReportByFilterHelper()
                            "
                          />
                          <label
                            class="custom-control-label"
                            for="separate_rate_in_report"
                            >Separate Different Rate <br />
                            <span class="text-muted small"
                              >(consolidated report)</span
                            ></label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <span v-if="shouldViewReport && payrolls.length">
                  <b v-if="!reportsLoading">
                    {{ payrolls.length || 0 }}/{{ total }}</b
                  >
                  <i
                    class="fas fa-spin fa-circle-notch"
                    v-if="reportsLoading"
                  ></i>
                  Item{{ reportsLoading || total != 1 ? "s" : "" }}
                  <i
                    class="fas fa-spin fa-circle-notch mx-1 pointer-cursor"
                    v-if="busy"
                    v-tooltip.top="
                      `%${
                        parseFloat(
                          ((payrolls.length || 0) / total) * 100
                        ).toFixed(2) || 0
                      }</br>Getting all data...`
                    "
                  >
                  </i>
                  <button
                    class="btn btn-link mx-3"
                    @click="
                      (uiFilter.optional_columns.automaticLoading = false),
                        (isCancelled = true)
                    "
                    v-if="busy"
                  >
                    <i
                      class="fas fa-spin fa-circle-notch"
                      v-if="isCancelled"
                    ></i>
                    <span v-else>Cancel</span>
                  </button>
                </span>
              </div>
              <div class="d-flex flex-wrap">
                <div class="d-flex mt-3 mr-3">
                  <i
                    class="
                      fa fa-info-circle
                      mr-5
                      pointer-cursor
                      d-flex
                      justify-content-start
                      align-items-center
                    "
                    v-if="busy"
                    v-tooltip.top="
                      'Buttons will be enabled after all data is loaded successfully.'
                    "
                  ></i>
                  <button
                    class="btn btn-theme text-uppercase"
                    :disabled="!shouldViewReport || reportsLoading || busy"
                    @click="handlePrintButton"
                  >
                    <i class="fas fa-print fa-lg"></i> Print
                  </button>
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn p-1"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="fas fa-cog"></i>
                    </button>
                    <div
                      class="dropdown-menu print-menu"
                      @click="$event.stopPropagation()"
                    >
                      <h3 class="mx-3 my-2">
                        <i class="fas fa-cog mr-2"></i>Print settings
                      </h3>
                      <div class="dropdown-divider"></div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="combine_cells_in_report"
                            v-model="printSettings.combine_cells"
                          />
                          <label
                            class="custom-control-label"
                            for="combine_cells_in_report"
                            >Combine Cells <br />
                            <span class="text-muted"
                              >(Print as shown in the page)</span
                            ></label
                          >
                        </div>
                      </div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="show_second_logo_in_report"
                            v-model="printSettings.show_second_logo_in_report"
                          />
                          <label
                            class="custom-control-label"
                            for="show_second_logo_in_report"
                            >Second Logo</label
                          >
                        </div>
                      </div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="show_name_in_report"
                            v-model="printSettings.show_name_in_report"
                          />
                          <label
                            class="custom-control-label"
                            for="show_name_in_report"
                            >Name</label
                          >
                        </div>
                      </div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="show_location_in_report"
                            v-model="printSettings.show_location_in_report"
                          />
                          <label
                            class="custom-control-label"
                            for="show_location_in_report"
                            >Location</label
                          >
                        </div>
                      </div>

                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="show_phone_number_in_report"
                            v-model="printSettings.show_phone_number_in_report"
                          />
                          <label
                            class="custom-control-label"
                            for="show_phone_number_in_report"
                            >Phone Number</label
                          >
                        </div>
                      </div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="show_email_in_report"
                            v-model="printSettings.show_email_in_report"
                          />
                          <label
                            class="custom-control-label"
                            for="show_email_in_report"
                            >Email</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="btn btn-danger text-uppercase mt-3 mr-3"
                  :disabled="!shouldViewReport || reportsLoading || busy"
                  @click="handleSavePdfButton"
                >
                  <i class="fas fa-lg fa-file-pdf"></i>
                  Save PDF
                </button>
                <div class="d-flex mr-3 mt-3" :class="busy ? 'disabled' : ''">
                  <JsonExcel
                    :data="printExcelData"
                    name="CRM-Payroll-Report"
                    worksheet="Payroll Report"
                    :fields="excel_fields"
                    :before-generate="loadRestOfData"
                    default-value=""
                    header="Payroll Report"
                  >
                    <button
                      class="btn btn-deep-success text-uppercase"
                      :disabled="!shouldViewReport || reportsLoading || busy"
                    >
                      <i class="far fa-file-excel fa-lg"></i> Save Excel
                    </button>
                  </JsonExcel>
                  <div
                    class="btn-group excel-control-group"
                    :class="`${
                      uiFilter.optional_columns.group_by_criteria != 0
                        ? ''
                        : 'transparent'
                    }`"
                  >
                    <button
                      type="button"
                      class="btn p-1"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="fas fa-cog"></i>
                    </button>
                    <div
                      class="dropdown-menu print-menu excel-menu"
                      @click="$event.stopPropagation()"
                    >
                      <h3 class="mx-3 my-2">
                        <i class="fas fa-cog mr-2"></i>Excel settings
                      </h3>
                      <div class="dropdown-divider"></div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            :key="printSettings.exportTotalOnly"
                            type="checkbox"
                            class="custom-control-input"
                            id="show_total_only_in_report"
                            v-model="printSettings.exportTotalOnly"
                            @change="handleExcelData()"
                          />
                          <label
                            class="custom-control-label"
                            for="show_total_only_in_report"
                            >Export Total Only</label
                          >
                        </div>
                      </div>
                      <div
                        :class="`dropdown-item ${
                          printSettings.exportTotalOnly ? '' : 'disabled'
                        }`"
                        v-if="uiFilter.optional_columns.group_by_criteria == 2"
                      >
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="separate_rate_in_report"
                            :key="printSettings.separateRate"
                            v-model="printSettings.separateRate"
                            @change="
                              (printSettings.exportTotalOnly = true),
                                handleExcelData()
                            "
                          />
                          <label
                            class="custom-control-label"
                            for="separate_rate_in_report"
                            >Separate Different Rate <br />
                            <span class="text-muted small"
                              >(consolidated report)</span
                            ></label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div :class="busy ? 'disabled' : ''">
                  <JsonExcel
                    :data="printExcelData"
                    name="CRM-Payroll-Report.csv"
                    :fields="excel_fields"
                    :before-generate="loadRestOfData"
                    default-value=""
                    type="csv"
                  >
                    <button
                      :disabled="!shouldViewReport || reportsLoading || busy"
                      class="btn btn-deep-success text-uppercase mt-3"
                    >
                      <i class="fa fa-file-csv fa-lg"></i> Save CSV
                    </button>
                  </JsonExcel>
                </div>
              </div>
            </div>
            <alert
              v-if="reportsLoading || reportIsPending"
              class="my-4"
            ></alert>
          </div>
        </div>
      </div>
    </div>
    <alert v-if="loading" />
    <div
      class="reportData row firstPage printPage"
      v-if="printSettings.combine_cells && !loading && !reportIsPending"
    >
      <div class="col-md-8 reportCompanyLogo">
        <img
          :src="
            printSettings.show_second_logo_in_report && companyData.second_logo
              ? companyData.second_logo
              : companyData.logo
          "
        />
      </div>
      <div class="row col-md-12 m-0 reportHeader">
        <div class="col-md-12" v-if="companyData.show_name_in_report">
          {{ companyData.name }}
        </div>
        <div class="col-md-12" v-if="companyData.show_bio_in_report">
          {{ companyData.bio }}
        </div>
      </div>
      <div class="row PatientData">
        <div class="col-md-12">
          <p
            v-if="
              providersNamesInReport.length == 1 &&
              printSettings.show_name_in_report
            "
          >
            <label>PROVIDER/ADMIN NAME: </label
            >{{
              providersNamesInReport != null
                ? providersNamesInReport.join(" , ")
                : ""
            }}
          </p>

          <p v-if="printSettings.show_email_in_report">
            <label>Email: </label>{{ companyData.email }}
          </p>
          <p v-if="printSettings.show_phone_number_in_report">
            <label>Phone Number: </label>{{ companyData.phone_number }}
          </p>
          <p v-if="printSettings.show_location_in_report">
            <label>Location: </label>{{ companyData.location }}
          </p>

          <p v-if="filter.dateRangeEnabled">
            <label>Report Dates Range: </label
            >{{
              this.prettyDate(
                this.submittedFilter.range.start,
                "MMM D, YYYY "
              ) +
              " - " +
              this.prettyDate(this.submittedFilter.range.end, "MMM D, YYYY ")
            }}
          </p>
        </div>
      </div>
    </div>
    <div
      v-if="!loading && shouldViewReport && !reportIsPending"
      class="card overflow-auto light-shadow border border-lg-0"
      :class="printSettings.combine_cells ? '' : 'noPrint'"
    >
      <div
        class="p-3 noPrint"
        v-if="allPayrolls.length == 0 && shouldViewReport && !reportsLoading"
      >
        No Data.
      </div>
      <div
        ref="payrollContainerOuterTable"
        class="overflow-auto border border-lg-0"
      >
        <div class="row m-0" v-show="processing">
          <div
            class="col-12 pdfCompanyLogo"
            v-if="
              companyData.logo ||
              (printSettings.show_second_logo_in_report &&
                companyData.second_logo)
            "
          >
            <img
              :src="
                printSettings.show_second_logo_in_report &&
                companyData.second_logo
                  ? companyData.second_logo
                  : companyData.logo
              "
            />
          </div>
          <div
            class="col-12 text-center mb-4"
            v-if="companyData.show_name_in_report"
          >
            {{ companyData.name }}
          </div>
        </div>
        <table
          v-if="allPayrolls.length > 0 && shouldViewReport && !reportsLoading"
          class="table table-sm table-striped reportTable mb-0"
          :class="printSettings.combine_cells ? '' : 'noPrint'"
          ref="payrollContainerTable"
        >
          <thead>
            <tr>
              <th rowspan="1" colspan="1">Provider/Admin</th>
              <th
                rowspan="1"
                colspan="1"
                v-if="uiFilter.optional_columns.userId"
              >
                ID
              </th>
              <th rowspan="1" colspan="1" v-if="uiFilter.optional_columns.hst">
                HST
              </th>
              <th rowspan="1" colspan="1">Service - Name</th>

              <th rowspan="1" colspan="1">Visit Date</th>

              <th rowspan="1" colspan="1">Client/User</th>

              <th
                rowspan="1"
                colspan="1"
                v-if="!uiFilter.optional_columns.mileage"
              >
                Qty
              </th>
              <th
                v-if="uiFilter.optional_columns.mileage"
                rowspan="1"
                colspan="1"
              >
                Hours
              </th>
              <th
                v-if="uiFilter.optional_columns.mileage"
                rowspan="1"
                colspan="1"
              >
                Flat
              </th>
              <th
                v-if="uiFilter.optional_columns.mileage"
                colspan="2"
                class="text-center"
              >
                Mileage
                <div
                  class="d-flex justify-content-between w-100 border-top pt-1"
                >
                  <div>$</div>
                  <div>Km</div>
                </div>
              </th>
              <th rowspan="1" colspan="1">Rate</th>
              <th rowspan="1" colspan="1" v-if="uiFilter.optional_columns.amt">
                Amt
              </th>
              <th rowspan="1" colspan="1">Expenses</th>
              <th rowspan="1" colspan="1" v-if="uiFilter.optional_columns.tax">
                Tax
              </th>
              <th
                rowspan="1"
                colspan="1"
                v-if="uiFilter.optional_columns.subTotal"
              >
                Sub Total
              </th>
              <th rowspan="1" colspan="1">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr class="totalRow like-header">
              <td :colspan="uiFilter.optional_columns.mileage ? 2 : 5"></td>
              <td colspan="1" v-if="uiFilter.optional_columns.amt"></td>
              <td colspan="1" v-if="uiFilter.optional_columns.userId"></td>
              <td colspan="1" v-if="uiFilter.optional_columns.hst"></td>
              <td
                colspan="1"
                v-if="
                  uiFilter.optional_columns.mileage &&
                  !uiFilter.optional_columns.amt
                "
              ></td>
              <td class="text-right">Total:</td>
              <td v-if="uiFilter.optional_columns.mileage">
                {{
                  `${+parseFloat(totalHours || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td class="moneyField" v-if="uiFilter.optional_columns.mileage">
                {{
                  `${+parseFloat(totalFlat || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td class="moneyField" v-if="uiFilter.optional_columns.mileage">
                {{
                  `${+parseFloat(totalMileage || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td v-if="uiFilter.optional_columns.mileage">
                {{
                  `${+parseFloat(totalKm || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td colspan="1" v-if="uiFilter.optional_columns.mileage">-</td>
              <td
                colspan="1"
                v-if="
                  uiFilter.optional_columns.mileage &&
                  uiFilter.optional_columns.amt
                "
              >
                -
              </td>
              <td class="moneyField">
                {{
                  `${+parseFloat(totalExpenses || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td class="moneyField" v-if="uiFilter.optional_columns.tax">
                {{
                  `${+parseFloat(totalTax || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>

              <td class="moneyField" v-if="uiFilter.optional_columns.subTotal">
                {{
                  `${+parseFloat(totalSubSum || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>

              <td class="moneyField">
                {{
                  `${+parseFloat(totalSum || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
            </tr>
            <tr
              v-for="provider in allPayrolls"
              :key="provider.provider_id"
              :class="{ subServiceTotalRow: provider.totalRow }"
            >
              <td v-if="!provider.totalRow">
                {{
                  provider.appointment != null
                    ? getAptProvider(provider.appointment).name || ""
                    : ""
                }}
              </td>
              <td v-if="!provider.totalRow && uiFilter.optional_columns.userId">
                {{
                  provider.appointment != null
                    ? getAptProvider(provider.appointment).user_id || ""
                    : ""
                }}
              </td>
              <td v-if="!provider.totalRow && uiFilter.optional_columns.hst">
                {{ getAptProvider(provider.appointment).hstNumber }}
              </td>
              <td>
                {{ provider.service != null ? provider.service.name : "-" }}
              </td>
              <td v-if="!provider.totalRow">
                {{
                  provider.appointment != null
                    ? prettyDate(
                        adjustDate(provider.appointment.start_time),
                        "MMM D, YYYY"
                      ) +
                      ":" +
                      prettyDate(
                        adjustDate(provider.appointment.start_time),
                        "hh:mm a"
                      ) +
                      " - " +
                      prettyDate(
                        adjustDate(provider.appointment.end_time),
                        "hh:mm a"
                      )
                    : ""
                }}
              </td>
              <td v-if="!provider.totalRow">
                {{
                  provider.appointment
                    ? getAptPatient(provider.appointment).name || ""
                    : ""
                }}
              </td>
              <td
                v-if="!provider.totalRow && !uiFilter.optional_columns.mileage"
              >
                {{
                  `${+parseFloat(provider.qty || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td
                v-if="!provider.totalRow && uiFilter.optional_columns.mileage"
                rowspan="1"
                colspan="1"
              >
                {{
                  provider.hours
                    ? `${+parseFloat(provider.hours).toFixed(2)}`.replace(
                        /(\d)(?=(\d{3})+(?!\d))/g,
                        "$1,"
                      )
                    : "-"
                }}
              </td>
              <td
                v-if="!provider.totalRow && uiFilter.optional_columns.mileage"
                rowspan="1"
                :class="`${provider.flat ? 'moneyField' : ''}`"
                colspan="1"
              >
                {{
                  provider.flat
                    ? `${+parseFloat(provider.flat).toFixed(2)}`.replace(
                        /(\d)(?=(\d{3})+(?!\d))/g,
                        "$1,"
                      )
                    : "-"
                }}
              </td>
              <td
                v-if="!provider.totalRow && uiFilter.optional_columns.mileage"
                rowspan="1"
                colspan="1"
                :class="`${provider.mileage ? 'moneyField' : ''}`"
              >
                {{
                  provider.mileage
                    ? `${+parseFloat(provider.mileage).toFixed(2)}`.replace(
                        /(\d)(?=(\d{3})+(?!\d))/g,
                        "$1,"
                      )
                    : "-"
                }}
              </td>
              <td
                v-if="!provider.totalRow && uiFilter.optional_columns.mileage"
                rowspan="1"
                colspan="1"
              >
                {{
                  provider.km
                    ? `${+parseFloat(provider.km).toFixed(2)}`.replace(
                        /(\d)(?=(\d{3})+(?!\d))/g,
                        "$1,"
                      )
                    : "-"
                }}
              </td>
              <td class="moneyField" v-if="!provider.totalRow">
                {{
                  `${+parseFloat(provider.provider_rate || 0).toFixed(
                    2
                  )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                }}
              </td>
              <td
                class="moneyField"
                v-if="!provider.totalRow && uiFilter.optional_columns.amt"
              >
                {{
                  `${+parseFloat(provider.amt || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td
                class="moneyField"
                v-if="!provider.totalRow"
                rowspan="1"
                colspan="1"
              >
                {{
                  `${+parseFloat(
                    (provider.appointment || {}).expenses || 0
                  ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                }}
              </td>

              <td
                class="moneyField"
                v-if="!provider.totalRow && uiFilter.optional_columns.tax"
                rowspan="1"
                colspan="1"
              >
                {{
                  `${+parseFloat(provider.calculated_tax || 0).toFixed(
                    2
                  )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                }}
              </td>

              <td
                class="moneyField"
                v-if="!provider.totalRow && uiFilter.optional_columns.subTotal"
              >
                {{
                  `${+parseFloat(provider.provider_subTotal || 0).toFixed(
                    2
                  )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                }}
              </td>
              <td class="moneyField" v-if="!provider.totalRow">
                {{
                  `${+parseFloat(provider.totalWithHst || 0).toFixed(
                    2
                  )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                }}
              </td>

              <!--  group by service  -->
              <td rowspan="1" colspan="2" v-if="provider.totalRow">
                {{ provider.totalService }}
              </td>
              <td
                rowspan="1"
                colspan="1"
                v-if="provider.totalRow && uiFilter.optional_columns.userId"
              ></td>
              <td
                rowspan="1"
                colspan="1"
                v-if="provider.totalRow && uiFilter.optional_columns.hst"
              ></td>
              <td rowspan="1" colspan="1" v-if="provider.totalRow"></td>
              <td
                rowspan="1"
                colspan="1"
                v-if="provider.totalRow && !uiFilter.optional_columns.mileage"
              >
                {{
                  `${+parseFloat(provider.totalQty || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td
                rowspan="1"
                colspan="1"
                v-if="provider.totalRow && uiFilter.optional_columns.mileage"
              >
                {{
                  provider.totalHours
                    ? `${+parseFloat(provider.totalHours).toFixed(2)}`.replace(
                        /(\d)(?=(\d{3})+(?!\d))/g,
                        "$1,"
                      )
                    : "-"
                }}
              </td>
              <td
                rowspan="1"
                colspan="1"
                :class="`${provider.totalFlat ? 'moneyField' : ''}`"
                v-if="provider.totalRow && uiFilter.optional_columns.mileage"
              >
                {{
                  provider.totalFlat
                    ? `${+parseFloat(provider.totalFlat).toFixed(2)}`.replace(
                        /(\d)(?=(\d{3})+(?!\d))/g,
                        "$1,"
                      )
                    : "-"
                }}
              </td>
              <td
                rowspan="1"
                colspan="1"
                :class="`${provider.totalMileage ? 'moneyField' : ''}`"
                v-if="provider.totalRow && uiFilter.optional_columns.mileage"
              >
                {{
                  provider.totalMileage
                    ? `${+parseFloat(provider.totalMileage).toFixed(
                        2
                      )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                    : "-"
                }}
              </td>
              <td
                rowspan="1"
                colspan="1"
                v-if="provider.totalRow && uiFilter.optional_columns.mileage"
              >
                {{
                  provider.totalKm
                    ? `${+parseFloat(provider.totalKm).toFixed(2)}`.replace(
                        /(\d)(?=(\d{3})+(?!\d))/g,
                        "$1,"
                      )
                    : "-"
                }}
              </td>
              <td rowspan="1" colspan="1" v-if="provider.totalRow">
                {{ `-` }}
              </td>

              <td
                class="moneyField"
                rowspan="1"
                colspan="1"
                v-if="provider.totalRow && uiFilter.optional_columns.amt"
              >
                {{
                  `${+parseFloat(provider.totalAmount || 0).toFixed(
                    2
                  )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                }}
              </td>
              <td
                class="moneyField"
                rowspan="1"
                colspan="1"
                v-if="provider.totalRow"
              >
                {{
                  `${+parseFloat(provider.totalExpenses || 0).toFixed(
                    2
                  )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                }}
              </td>

              <td
                class="moneyField"
                rowspan="1"
                colspan="1"
                v-if="provider.totalRow && uiFilter.optional_columns.tax"
              >
                {{
                  `${+parseFloat(provider.totalCalculatedTax || 0).toFixed(
                    2
                  )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                }}
              </td>

              <td
                class="moneyField"
                rowspan="1"
                colspan="1"
                v-if="provider.totalRow && uiFilter.optional_columns.subTotal"
              >
                {{
                  `${+parseFloat(provider.subTotal || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>

              <td
                class="moneyField"
                rowspan="1"
                colspan="1"
                v-if="provider.totalRow"
              >
                {{
                  `${+parseFloat(provider.totalWithHst || 0).toFixed(
                    2
                  )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                }}
              </td>
            </tr>
            <tr class="totalRow">
              <td :colspan="uiFilter.optional_columns.mileage ? 2 : 5"></td>
              <td colspan="1" v-if="uiFilter.optional_columns.amt"></td>
              <td colspan="1" v-if="uiFilter.optional_columns.userId"></td>
              <td colspan="1" v-if="uiFilter.optional_columns.hst"></td>
              <td
                colspan="1"
                v-if="
                  uiFilter.optional_columns.mileage &&
                  !uiFilter.optional_columns.amt
                "
              ></td>
              <td class="text-right">Total:</td>
              <td v-if="uiFilter.optional_columns.mileage">
                {{
                  `${+parseFloat(totalHours || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td class="moneyField" v-if="uiFilter.optional_columns.mileage">
                {{
                  `${+parseFloat(totalFlat || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td class="moneyField" v-if="uiFilter.optional_columns.mileage">
                {{
                  `${+parseFloat(totalMileage || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td v-if="uiFilter.optional_columns.mileage">
                {{
                  `${+parseFloat(totalKm || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td colspan="1" v-if="uiFilter.optional_columns.mileage">-</td>
              <td
                colspan="1"
                v-if="
                  uiFilter.optional_columns.mileage &&
                  uiFilter.optional_columns.amt
                "
              >
                -
              </td>

              <td class="moneyField">
                {{
                  `${+parseFloat(totalExpenses || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td class="moneyField" v-if="uiFilter.optional_columns.tax">
                {{
                  `${+parseFloat(totalTax || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>

              <td class="moneyField" v-if="uiFilter.optional_columns.subTotal">
                {{
                  `${+parseFloat(totalSubSum || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>

              <td class="moneyField">
                {{
                  `${+parseFloat(totalSum || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <mugen-scroll
          class="sticky-bottom-100"
          :handler="loadMore"
          :should-handle="
            !busy &&
            !reportsLoading &&
            !reportIsPending &&
            shouldViewReport &&
            paginationUsed &&
            !uiFilter.optional_columns.automaticLoading
          "
          scroll-container="wrap"
        >
          <alert
            v-if="busy && !reportIsPending"
            class="
              text-center
              p-2
              mb-0
              mobile-tablet-loadmore mobile-tablet-shadow-card
              rounded-0
            "
            ><i class="fas fa-spin fa-circle-notch mr-2"></i>Loading more
            data...</alert
          >
        </mugen-scroll>
      </div>
    </div>
    <div class="container printableReport" v-if="allPayrolls.length > 0">
      <div
        class="reportData row firstPage printPage"
        v-if="!printSettings.combine_cells"
      >
        <div class="col-md-8 reportCompanyLogo">
          <img
            :src="
              printSettings.show_second_logo_in_report &&
              companyData.second_logo
                ? companyData.second_logo
                : companyData.logo
            "
          />
        </div>
        <div class="row col-md-12 m-0 reportHeader">
          <div class="col-md-12" v-if="companyData.show_name_in_report">
            {{ companyData.name }}
          </div>
          <div class="col-md-12" v-if="companyData.show_bio_in_report">
            {{ companyData.bio }}
          </div>
        </div>
        <div class="row PatientData">
          <div class="col-md-12">
            <p
              v-if="
                providersNamesInReport.length == 1 &&
                printSettings.show_name_in_report
              "
            >
              <label>PROVIDER/ADMIN NAME: </label
              >{{
                providersNamesInReport != null
                  ? providersNamesInReport.join(" , ")
                  : ""
              }}
            </p>

            <p v-if="printSettings.show_email_in_report">
              <label>Email: </label>{{ companyData.email }}
            </p>
            <p v-if="printSettings.show_phone_number_in_report">
              <label>Phone Number: </label>{{ companyData.phone_number }}
            </p>
            <p v-if="printSettings.show_location_in_report">
              <label>Location: </label>{{ companyData.location }}
            </p>

            <p v-if="filter.dateRangeEnabled">
              <label>Report Dates Range: </label
              >{{
                this.prettyDate(
                  this.submittedFilter.range.start,
                  "MMM D, YYYY "
                ) +
                " - " +
                this.prettyDate(this.submittedFilter.range.end, "MMM D, YYYY ")
              }}
            </p>
          </div>
        </div>
      </div>
      <!-- report Pages -->

      <div v-if="!printSettings.combine_cells">
        <div
          class="reportData row printPage"
          v-for="providerReports in this.groupedByProviderId"
          :key="`${providerReports.id || Math.random()}_${
            (providerReports.service || {}).id
          }`"
        >
          <div class="row">
            <div
              class="pageTopReportInfoHeader col-12"
              style="
                font-weight: bold;
                border-bottom: 1px solid;
                margin-bottom: 10px;
              "
            >
              <div
                class="col-6"
                style="float: left"
                v-if="printSettings.show_name_in_report"
              >
                {{ companyData.name }} Report
              </div>

              <div
                class="col-6"
                style="float: right; text-align: right"
                v-show="filter.dateRangeEnabled"
              >
                Report Dates Range:
                {{
                  prettyDate(filter.range.start, "MMM D, YYYY ") +
                  " - " +
                  prettyDate(filter.range.end, "MMM D, YYYY ")
                }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div
                class="col-md-5"
                style="text-align: center; margin: 0px auto"
              >
                <img
                  :src="
                    printSettings.show_second_logo_in_report &&
                    companyData.second_logo
                      ? companyData.second_logo
                      : companyData.logo
                  "
                  style="height: 100px"
                  class="reportCompanyLogo"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="col-4" v-if="printSettings.show_name_in_report">
                <label style="font-weight: bold; text-align: right"
                  >Name:</label
                >

                {{
                  providerReports != null && providerReports[0].provider != null
                    ? providerReports[0].provider.name || ""
                    : ""
                }}
              </div>
            </div>
          </div>
          <!-- Print Table -->
          <table class="table table-sm table-striped mb-0">
            <thead v-if="providerReports != null">
              <tr>
                <th
                  :rowspan="uiFilter.optional_columns.mileage ? '2' : '1'"
                  colspan="1"
                >
                  Service
                </th>
                <th rowspan="2" colspan="1">Visit Date</th>
                <th
                  rowspan="2"
                  colspan="1"
                  v-if="uiFilter.optional_columns.hst"
                >
                  HST
                </th>
                <th
                  rowspan="1"
                  :colspan="`${uiFilter.optional_columns.amt ? '3' : '2'}`"
                  class="text-center"
                  v-if="!uiFilter.optional_columns.mileage"
                >
                  Hours
                </th>
                <th
                  :rowspan="uiFilter.optional_columns.mileage ? '2' : '1'"
                  v-if="uiFilter.optional_columns.mileage"
                >
                  Rate
                </th>
                <th
                  v-if="
                    uiFilter.optional_columns.mileage &&
                    uiFilter.optional_columns.amt
                  "
                  rowspan="2"
                >
                  Amt
                </th>
                <th rowspan="2" colspan="1">Client/User</th>
                <th
                  v-if="uiFilter.optional_columns.mileage"
                  rowspan="2"
                  colspan="1"
                >
                  Hours
                </th>
                <th
                  v-if="uiFilter.optional_columns.mileage"
                  rowspan="2"
                  colspan="1"
                >
                  Flat
                </th>
                <th
                  v-if="uiFilter.optional_columns.mileage"
                  rowspan="1"
                  colspan="2"
                  class="text-center"
                >
                  Mileage
                </th>
                <th rowspan="2" colspan="1">Expenses</th>
                <th
                  rowspan="2"
                  colspan="1"
                  v-if="uiFilter.optional_columns.tax"
                >
                  Tax
                </th>
                <th
                  rowspan="2"
                  colspan="1"
                  v-if="uiFilter.optional_columns.subTotal"
                >
                  Sub Total
                </th>

                <th rowspan="2" colspan="1">Total</th>
              </tr>
              <tr v-if="!uiFilter.optional_columns.mileage">
                <th>Name</th>
                <th>Qty</th>
                <th>Rate</th>
                <th v-if="uiFilter.optional_columns.amt">Amt</th>
              </tr>
              <tr v-if="uiFilter.optional_columns.mileage">
                <th>$</th>
                <th>Km</th>
              </tr>
            </thead>
            <tbody>
              <tr class="totalRow like-header">
                <td :colspan="uiFilter.optional_columns.mileage ? 1 : 4"></td>
                <td colspan="1" v-if="uiFilter.optional_columns.amt"></td>
                <td colspan="2" v-if="uiFilter.optional_columns.mileage"></td>
                <td colspan="1" v-if="uiFilter.optional_columns.hst"></td>
                <td class="text-right">Total:</td>

                <td v-if="uiFilter.optional_columns.mileage">
                  {{
                    `${+parseFloat(
                      (providerTotalHours = calculateTotalPerKey(
                        providerReports,
                        "hours"
                      ))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td class="moneyField" v-if="uiFilter.optional_columns.mileage">
                  {{
                    `${+parseFloat(
                      (providerTotalFlat = calculateTotalPerKey(
                        providerReports,
                        "flat"
                      ))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td class="moneyField" v-if="uiFilter.optional_columns.mileage">
                  {{
                    `${+parseFloat(
                      (providerTotalMileage = calculateTotalPerKey(
                        providerReports,
                        "mileage"
                      ))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td v-if="uiFilter.optional_columns.mileage">
                  {{
                    `${+parseFloat(
                      (providerTotalKm = calculateTotalPerKey(
                        providerReports,
                        "km"
                      ))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td class="moneyField">
                  {{
                    `${+parseFloat(
                      (providerTotalExpenses = calculateTotalPerKey(
                        providerReports,
                        "expenses"
                      ))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>

                <td class="moneyField" v-if="uiFilter.optional_columns.tax">
                  {{
                    `${+parseFloat(
                      (providerTotalTax = calculateTotalTax(providerReports))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>

                <td
                  class="moneyField"
                  v-if="uiFilter.optional_columns.subTotal"
                >
                  {{
                    `${+parseFloat(
                      (providerTotalSubSum =
                        calculateTotalSubSum(providerReports))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td class="moneyField">
                  {{
                    `${+parseFloat(
                      (providerTotalSum = calculateTotalSum(providerReports))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
              </tr>
              <tr
                v-for="reportItem in providerReports"
                :key="`${reportItem.id || Math.random()}_${
                  reportItem.service.id
                }`"
              >
                <td>
                  {{ reportItem.service.name }}
                </td>

                <td>
                  {{
                    reportItem.appointment != null
                      ? prettyDate(
                          adjustDate(reportItem.appointment.start_time),
                          "MMM D, YYYY"
                        ) +
                        ":" +
                        prettyDate(
                          adjustDate(reportItem.appointment.start_time),
                          "hh:mm a"
                        ) +
                        " - " +
                        prettyDate(
                          adjustDate(reportItem.appointment.end_time),
                          "hh:mm a"
                        )
                      : ""
                  }}
                </td>
                <td v-if="uiFilter.optional_columns.hst">
                  {{ getAptProvider(reportItem.appointment).hstNumber }}
                </td>
                <td v-if="!uiFilter.optional_columns.mileage">
                  {{
                    `${+parseFloat(reportItem.qty || 0).toFixed(2)}`.replace(
                      /(\d)(?=(\d{3})+(?!\d))/g,
                      "$1,"
                    )
                  }}
                </td>
                <td class="moneyField">
                  {{
                    `${+parseFloat(reportItem.provider_rate || 0).toFixed(
                      2
                    )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td class="moneyField" v-if="uiFilter.optional_columns.amt">
                  {{
                    `${+parseFloat(reportItem.amt || 0).toFixed(2)}`.replace(
                      /(\d)(?=(\d{3})+(?!\d))/g,
                      "$1,"
                    )
                  }}
                </td>

                <td>
                  {{
                    reportItem.appointment
                      ? getAptPatient(reportItem.appointment).name || ""
                      : ""
                  }}
                </td>
                <td v-if="uiFilter.optional_columns.mileage">
                  {{
                    reportItem.hours
                      ? `${+parseFloat(reportItem.hours).toFixed(2)}`.replace(
                          /(\d)(?=(\d{3})+(?!\d))/g,
                          "$1,"
                        )
                      : "-"
                  }}
                </td>
                <td
                  v-if="uiFilter.optional_columns.mileage"
                  :class="`${reportItem.flat ? 'moneyField' : ''}`"
                >
                  {{
                    reportItem.flat
                      ? `${+parseFloat(reportItem.flat).toFixed(2)}`.replace(
                          /(\d)(?=(\d{3})+(?!\d))/g,
                          "$1,"
                        )
                      : "-"
                  }}
                </td>
                <td
                  v-if="uiFilter.optional_columns.mileage"
                  :class="`${reportItem.mileage ? 'moneyField' : ''}`"
                >
                  {{
                    reportItem.mileage
                      ? `${+parseFloat(reportItem.mileage).toFixed(2)}`.replace(
                          /(\d)(?=(\d{3})+(?!\d))/g,
                          "$1,"
                        )
                      : "-"
                  }}
                </td>
                <td v-if="uiFilter.optional_columns.mileage">
                  {{
                    reportItem.km
                      ? `${+parseFloat(reportItem.km).toFixed(2)}`.replace(
                          /(\d)(?=(\d{3})+(?!\d))/g,
                          "$1,"
                        )
                      : "-"
                  }}
                </td>
                <td class="moneyField" rowspan="1" colspan="1">
                  {{
                    `${+parseFloat(
                      (reportItem.appointment || {}).expenses || 0
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td
                  class="moneyField"
                  rowspan="1"
                  colspan="1"
                  v-if="uiFilter.optional_columns.tax"
                >
                  {{
                    `${+parseFloat(reportItem.calculated_tax || 0).toFixed(
                      2
                    )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td
                  class="moneyField"
                  v-if="uiFilter.optional_columns.subTotal"
                >
                  {{
                    `${+parseFloat(reportItem.provider_subTotal || 0).toFixed(
                      2
                    )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td class="moneyField">
                  {{
                    `${(+parseFloat(
                      parseFloat(reportItem.provider_subTotal || 0) +
                        (reportItem.calculated_tax &&
                          parseFloat(reportItem.calculated_tax || 0))
                    )).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
              </tr>
              <!-- total Row -->
              <tr class="totalRow">
                <td :colspan="uiFilter.optional_columns.mileage ? 1 : 4"></td>
                <td colspan="1" v-if="uiFilter.optional_columns.amt"></td>
                <td colspan="2" v-if="uiFilter.optional_columns.mileage"></td>
                <td colspan="1" v-if="uiFilter.optional_columns.hst"></td>
                <td class="text-right">Total:</td>
                <td v-if="uiFilter.optional_columns.mileage">
                  {{
                    `${+parseFloat(
                      (providerTotalHours = calculateTotalPerKey(
                        providerReports,
                        "hours"
                      ))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td class="moneyField" v-if="uiFilter.optional_columns.mileage">
                  {{
                    `${+parseFloat(
                      (providerTotalFlat = calculateTotalPerKey(
                        providerReports,
                        "flat"
                      ))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td class="moneyField" v-if="uiFilter.optional_columns.mileage">
                  {{
                    `${+parseFloat(
                      (providerTotalMileage = calculateTotalPerKey(
                        providerReports,
                        "mileage"
                      ))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td v-if="uiFilter.optional_columns.mileage">
                  {{
                    `${+parseFloat(
                      (providerTotalKm = calculateTotalPerKey(
                        providerReports,
                        "km"
                      ))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>

                <td class="moneyField">
                  {{
                    `${+parseFloat(
                      (providerTotalExpenses = calculateTotalPerKey(
                        providerReports,
                        "expenses"
                      ))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>

                <td class="moneyField" v-if="uiFilter.optional_columns.tax">
                  {{
                    `${+parseFloat(
                      (providerTotalTax = calculateTotalTax(providerReports))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>

                <td
                  class="moneyField"
                  v-if="uiFilter.optional_columns.subTotal"
                >
                  {{
                    `${+parseFloat(
                      (providerTotalSubSum =
                        calculateTotalSubSum(providerReports))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td class="moneyField">
                  {{
                    `${+parseFloat(
                      (providerTotalSum = calculateTotalSum(providerReports))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--End Page -->
      </div>
    </div>
    <loader v-if="processing">
      <b class="my-4 text-center" v-html="processingText"></b>
      <button
        class="btn btn-link border-0"
        @click="isCancelled = true"
        v-if="payrolls.length != total"
      >
        <i class="fas fa-spin fa-circle-notch" v-if="isCancelled"></i>
        <span v-else>Cancel</span>
      </button>
    </loader>
  </div>
</template>
<script>
import dateFormat from "@/utils/dateFormat";
import { mapActions, mapState } from "vuex";
import { orderBy } from "lodash";
import helpers from "@/utils/helpers";
import Multiselect from "vue-multiselect";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import JsonExcel from "vue-json-excel";
import Swal from "sweetalert2";
import html2pdf from "html2pdf.js";
import MugenScroll from "vue-mugen-scroll";

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: "payroll-report",
  props: ["userIdProp", "isAdminProp"],
  components: {
    Multiselect,
    JsonExcel,
    MugenScroll,
  },
  data() {
    return {
      showReportClicked: false,
      isCancelled: false,
      excelOnly: false,
      reportIsPending: false,
      processing: false,
      percent: -1,
      processingText: "",
      printExcelData: [],
      printSettings: {
        combine_cells: false,
        separateRate: false,
        exportTotalOnly: false,
      },
      hst_percentage: 0.13,
      shouldViewReport: false,
      gettingClasses: false,
      paginationUsed: true,
      busy: false,
      limit: 300,
      gettingProvidersManagers: false,
      totalTax: 0,
      totalSum: 0,
      totalExpenses: 0,
      totalHours: 0,
      totalHoursMoney: 0,
      totalFlat: 0,
      totalMileage: 0,
      totalKm: 0,
      providerTotalTax: 0,
      providerTotalSum: 0,
      providerTotalExpenses: 0,
      providerTotalHours: 0,
      providerTotalFlat: 0,
      providerTotalKm: 0,
      providerTotalMileage: 0,
      providersNamesInReport: [],
      groupedByProviderId: [],
      printClicked: "",
      allPayrolls: [],
      gettingServices: false,
      services: [],
      mainServices: [],
      loading: false,
      saving: false,
      masks: {
        input: "YYYY-MM-DD",
      },
      uiFilter: {
        groupByOptions: [
          { value: 0, label: "None" },
          { value: 1, label: "Services" },
          { value: 2, label: "Providers" },
        ],
        nonMainGroupByOptions: [
          { value: 0, label: "None" },
          { value: 1, label: "Services" },
        ],
        billableOptions: [
          { value: -1, label: "All" },
          { value: 0, label: "Non Billable" },
          { value: 1, label: "Billable" },
        ],
        optional_columns: {
          combine_cells: true,
          group_by_criteria: 1,
          mileage: false,
          hst: false,
          userId: false,
          amt: true,
          tax: true,
          subTotal: true,
          automaticLoading: false,
        },
      },
      submittedFilter: {
        providers: "",
        providersManager: "",
        classification: "",
        service: "",
        dateRangeEnabled: true,
        filterIsDirty: true,
        pagination: false,
        non_billable: 1,
        range: {
          start: new Date(),
          end: new Date(),
        },
      },
      filter: {
        providers: "",
        providersManager: "",
        classification: "",
        service: "",
        dateRangeEnabled: true,
        filterIsDirty: true,
        pagination: false,
        non_billable: 1,
        range: {
          start: new Date(),
          end: new Date(),
        },
      },
    };
  },
  watch: {
    showReportClicked: function (newVal) {
      if (newVal && !this.isCancelled) {
        if (this.uiFilter.optional_columns.automaticLoading) {
          return this.loadRestOfData(true).then(() => {
            this.showReportClicked = false;
          });
        } else {
          this.showReportClicked = false;
        }
      }
    },
    isCancelled: function (newVal) {
      if (newVal && this.reportIsPending) {
        this.getReportByFilterHelper().then(() => {
          this.reportIsPending = false;
          this.isCancelled = false;
        });
      }
    },
  },
  computed: {
    ...mapState({
      payrolls: (state) => state.reports.payrollData,
      reportsLoading: (state) => state.reports.isLoading,
      companyData: (state) => state.settings.companyProfile,
      users: (state) => state.security.usersWithRoles,
      usersWithRolesLoading: (state) => state.security.usersWithRolesLoading,
      authUser: (state) => state.auth.user,
      invoiceProviders: (state) => state.providers.invoiceProviders,
      current: (state) => state.reports.payrollPagination.current,
      total: (state) => state.reports.payrollPagination.total,
      classifications: (state) =>
        state.classifications.data.filter((item) => item.role_id == 2),
    }),
    providersAdminsList: function () {
      if (this.users && this.users.length) {
        return this.users.filter(
          (user) => user.roleId === 2 || user.roleId === 1 || user.roleId === 5
        );
      } else {
        return [];
      }
    },
    providersManagers: function () {
      if (this.users && this.users.length) {
        return this.users.filter(
          (user) => user.roleId === 1 || user.roleId === 5
        );
      } else {
        return [];
      }
    },
    excel_fields: function () {
      let fields = {};
      if (
        this.uiFilter.optional_columns.group_by_criteria != 1 ||
        !this.printSettings.exportTotalOnly
      ) {
        fields = {
          ...fields,
          "Provider/Admin Name": {
            field: "appointment",
            callback: (value) => {
              if (value == "total") {
                return "TOTAL";
              } else if (value == "-----------") {
                return value;
              } else if (typeof value === "string") {
                return value;
              }
              return `${this.getAptProvider(value).name || ""}`;
            },
          },
        };
      }
      if (
        this.uiFilter.optional_columns.userId &&
        (this.uiFilter.optional_columns.group_by_criteria != 1 ||
          !this.printSettings.exportTotalOnly)
      ) {
        fields = {
          ...fields,
          ID: {
            field: "providerId",
            callback: (value) => {
              if (value == "total" || value == "-----------") {
                return "------------";
              }
              return value;
            },
          },
        };
      }
      if (
        this.uiFilter.optional_columns.hst &&
        (this.uiFilter.optional_columns.group_by_criteria != 1 ||
          !this.printSettings.exportTotalOnly)
      ) {
        fields = {
          ...fields,
          HST: {
            field: "hst",
            callback: (value) => {
              if (value == "total" || value == "-----------") {
                return "------------";
              }
              return value;
            },
          },
        };
      }
      if (
        (!this.printSettings.separateRate &&
          !this.printSettings.exportTotalOnly) ||
        this.uiFilter.optional_columns.group_by_criteria != 2
      ) {
        if (
          (!this.printSettings.separateRate &&
            !this.printSettings.exportTotalOnly) ||
          this.uiFilter.optional_columns.group_by_criteria == 0 ||
          (this.uiFilter.optional_columns.group_by_criteria == 1 &&
            !this.printSettings.exportTotalOnly)
        ) {
          fields = {
            ...fields,
            "User Name": {
              field: "appointment",
              callback: (value) => {
                if (value == "total" || value == "-----------") {
                  return "------------";
                } else if (typeof value === "string") {
                  return "------------";
                }
                return `${this.getAptPatient(value).name || ""}`;
              },
            },
          };
        }
        fields = {
          ...fields,
          "Service Name": {
            field: "service",
            callback: (value) => {
              if (value == "total" || value == "-----------") {
                return "------------";
              } else if (typeof value === "string") {
                return "------------";
              }
              return `${value != null ? value.name : ""}`;
            },
          },
        };
        if (
          ((!this.printSettings.separateRate ||
            this.uiFilter.optional_columns.group_by_criteria == 1) &&
            !this.printSettings.exportTotalOnly) ||
          this.uiFilter.optional_columns.group_by_criteria == 0
        ) {
          fields = {
            ...fields,
            "Visit Date": {
              field: "appointment",
              callback: (appointment) => {
                if (appointment == "total" || appointment == "-----------") {
                  return "---------------------";
                } else if (typeof appointment === "string") {
                  return "---------------------";
                }
                return appointment != null
                  ? this.prettyDate(
                      this.adjustDate(appointment.start_time),
                      "MMM D, YYYY"
                    ) +
                      ":" +
                      this.prettyDate(
                        this.adjustDate(appointment.start_time),
                        "hh:mm a"
                      ) +
                      " - " +
                      this.prettyDate(
                        this.adjustDate(appointment.end_time),
                        "hh:mm a"
                      )
                  : "";
              },
            },
            MEMO: {
              field: "appointment",
              callback: (appointment) => {
                if (appointment == "total" || appointment == "-----------") {
                  return "-----------";
                } else if (typeof appointment === "string") {
                  return "-----------";
                }
                return appointment && appointment.memo ? appointment.memo : "-";
              },
            },
          };
        }
      }

      if (
        !this.printSettings.exportTotalOnly ||
        (this.printSettings.separateRate &&
          this.uiFilter.optional_columns.group_by_criteria == 2) ||
        this.uiFilter.optional_columns.group_by_criteria == 0
      ) {
        fields = {
          ...fields,
          Rate: {
            field: "provider_rate",
            callback: (value) => {
              if (isNaN(parseFloat(value))) {
                return value;
              }
              return `${+parseFloat(value).toFixed(2)}`.replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                "$1,"
              );
            },
          },
        };
      }
      if (this.uiFilter.optional_columns.mileage) {
        fields = {
          ...fields,
          "Hours(H)": {
            field: "hours",
            callback: (value) => {
              if (isNaN(parseFloat(value))) {
                return value;
              }
              return `${+parseFloat(value).toFixed(2)}`.replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                "$1,"
              );
            },
          },
          "Hours($)": {
            field: "hoursMoney",
            callback: (value) => {
              if (isNaN(parseFloat(value))) {
                return value;
              }
              return `${+parseFloat(value).toFixed(2)}`.replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                "$1,"
              );
            },
          },
          Flat: {
            field: "flat",
            callback: (value) => {
              if (isNaN(parseFloat(value))) {
                return value;
              }
              return `${+parseFloat(value).toFixed(2)}`.replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                "$1,"
              );
            },
          },
          "Mileage($)": {
            field: "mileage",
            callback: (value) => {
              if (isNaN(parseFloat(value))) {
                return value;
              }
              return `${+parseFloat(value).toFixed(2)}`.replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                "$1,"
              );
            },
          },
          "Mileage(KM)": {
            field: "km",
            callback: (value) => {
              if (isNaN(parseFloat(value))) {
                return value;
              }
              return `${+parseFloat(value).toFixed(2)}`.replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                "$1,"
              );
            },
          },
        };
      } else {
        fields = {
          ...fields,
          QTY: {
            field: "qty",
            callback: (value) => {
              if (isNaN(parseFloat(value))) {
                return value;
              }
              return `${+parseFloat(value).toFixed(2)}`.replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                "$1,"
              );
            },
          },
        };
      }

      if (this.uiFilter.optional_columns.amt) {
        fields = {
          ...fields,
          AMT: {
            field: "amt",
            callback: (value) => {
              if (isNaN(parseFloat(value))) {
                return value;
              }
              return `${+parseFloat(value).toFixed(2)}`.replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                "$1,"
              );
            },
          },
        };
      }

      if (this.uiFilter.optional_columns.tax) {
        fields = {
          ...fields,
          Tax: {
            field: "calculated_tax",
            callback: (value) => {
              if (isNaN(parseFloat(value))) {
                return value;
              }
              return `${+parseFloat(value).toFixed(2)}`.replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                "$1,"
              );
            },
          },
        };
      }

      fields = {
        ...fields,
        Expenses: {
          field: "expenses",
          callback: (value) => {
            if (value == "total" || value == "-----------") {
              return "-----------";
            } else if (
              typeof value === "string" &&
              value.indexOf("Total") > -1
            ) {
              return "-----------";
            }
            return `${+parseFloat(value).toFixed(2)}`.replace(
              /(\d)(?=(\d{3})+(?!\d))/g,
              "$1,"
            );
          },
        },
      };

      if (this.uiFilter.optional_columns.subTotal) {
        fields = {
          ...fields,
          "Sub Total": {
            field: "provider_subTotal",
            callback: (value) => {
              if (value == "-----------") {
                return value;
              }
              return `${+parseFloat(`${value}`.replace(/,/g, "")).toFixed(
                2
              )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            },
          },
        };
      }
      fields = {
        ...fields,
        Total: {
          field: "totalWithHst",
          callback: (value) => {
            if (isNaN(parseFloat(value))) {
              return value;
            }
            return `${+parseFloat(value).toFixed(2)}`.replace(
              /(\d)(?=(\d{3})+(?!\d))/g,
              "$1,"
            );
          },
        },
      };

      return fields;
    },
  },
  created() {
    this.getCompanyProfile().then((companyData) => {
      if (companyData) {
        this.printSettings = {
          ...this.printSettings,
          show_name_in_report: companyData.show_name_in_report,
          show_email_in_report: companyData.show_email_in_report,
          show_location_in_report: companyData.show_location_in_report,
          show_phone_number_in_report: companyData.show_phone_number_in_report,
        };
      }
    });
    if (this.userIdProp) {
      this.filter.providers = this.userIdProp;
    } else {
      this.gettingClasses = true;
      this.gettingProvidersManagers = true;
      this.gettingServices = true;
      this.getClassifications().then(() => {
        this.gettingClasses = false;
      });
      this.getInvoiceProviders().then(() => {
        this.gettingProvidersManagers = false;
      });
      this.getPayrollServices().then((data) => {
        this.services = data || [];
        this.mainServices = this.services.filter(
          (item) => !item.parent_service_id
        );
        this.gettingServices = false;
      });
    }
  },
  methods: {
    ...mapActions({
      getReportsByDates: "reports/getPayrollSpecificDates",
      getCompanyProfile: "settings/getCompanyProfile",
      getClassifications: "classifications/get",
      getPayrollServices: "reports/getServices",
      getInvoiceProviders: "providers/getInvoiceProviders",
      setPayrollCurrentPage: "reports/setPayrollCurrentPage",
    }),
    dateFormat: dateFormat,
    dateIsBetween: helpers.dateIsBetween,
    adjustDate: function (time) {
      return dayjs(time)
        .add(-1 * dayjs(time).utcOffset(), "minute")
        .utc()
        .format();
    },
    removeOldWrongData: function (input) {
      return (input || []).filter(
        (value, index, self) =>
          index ===
            self.findIndex(
              (t) =>
                t.id === value.id &&
                (t.service || {}).id === (value.service || {}).id &&
                (t.appointment || {}).start_time ===
                  (value.appointment || {}).start_time &&
                (t.appointment || {}).end_time ===
                  (value.appointment || {}).end_time &&
                t.total == value.total
            ) || value.totalRow
      );
    },
    filterByDate: function (currentPage = 0) {
      let vm = this;
      //filter based on date
      if (
        this.submittedFilter.range.start != null &&
        this.submittedFilter.range.end != null
      ) {
        const payload = {};
        const startDateTime = `${dateFormat(
          vm.submittedFilter.range.start,
          "YYYY-MM-DD"
        )}T00:00:00.000000Z`;
        const endDateTime = `${dateFormat(
          vm.submittedFilter.range.end,
          "YYYY-MM-DD"
        )}T23:59:59.000000Z`;

        const canadaOffsetStart = dayjs
          .tz(new Date(startDateTime), "America/Toronto")
          .utcOffset();
        const canadaOffsetEnd = dayjs
          .tz(new Date(endDateTime), "America/Toronto")
          .utcOffset();
        payload.start = dayjs(startDateTime)
          .add(canadaOffsetStart, "minute")
          .utc()
          .format();

        payload.end = dayjs(endDateTime)
          .add(canadaOffsetEnd, "minute")
          .utc()
          .format();

        if (this.submittedFilter.classification != "") {
          payload.classes = `[${this.submittedFilter.classification
            .map((item) => item.id)
            .join(",")}]`;
        }

        if (this.submittedFilter.service != "") {
          payload.services = `[${this.submittedFilter.service
            .map((item) => item.id)
            .join(",")}]`;
        }

        if (this.submittedFilter.providers != "") {
          payload.providerId = this.submittedFilter.providers;
        }

        if (this.submittedFilter.providersManager != "") {
          payload.admin = this.submittedFilter.providersManager;
        }

        payload.billable = this.submittedFilter.non_billable;

        if (this.submittedFilter.pagination) {
          payload.limit = this.limit;
          if (currentPage > 0) {
            payload.page = currentPage;
          }
        }

        return this.getReportsByDates(payload).then(() => {
          this.allPayrolls = this.itemsWithApt(this.payrolls);
          if (!this.busy) {
            this.filter.filterIsDirty = false;
          }
          return Promise.resolve();
        });
      }
      return Promise.resolve();
    },
    itemsWithApt: function (input) {
      let vm = this;
      return JSON.parse(
        JSON.stringify(
          (input || []).filter(
            (item) =>
              item.appointment &&
              [1, 2, 5].includes(vm.getAptProvider(item.appointment).role_id)
          )
        )
      );
    },
    calculateTotalSubSum(objectToCalculate) {
      return objectToCalculate.reduce(function (tot, arr) {
        return tot + parseFloat(arr.provider_subTotal || 0);
        // set initial value as 0
      }, 0);
    },
    calculateTotalSum(objectToCalculate) {
      return objectToCalculate.reduce(function (tot, arr) {
        // return the sum with previous value
        return tot + parseFloat(arr.totalWithHst || 0);
        // set initial value as 0
      }, 0);
    },
    calculateTotalTax(objectToCalculate) {
      return objectToCalculate.reduce(function (tot, arr) {
        // return the sum with previous value
        return tot + parseFloat(arr.calculated_tax || 0);
        // set initial value as 0
      }, 0);
    },
    calculateTotalPerKey(objectToCalculate, key) {
      return objectToCalculate.reduce(function (tot, arr) {
        // return the sum with previous value
        return tot + parseFloat(arr[key] || 0);
        // set initial value as 0
      }, 0);
    },
    groupProvidersForReport() {
      let vm = this;
      this.totalSubSum = 0;

      let tempPayroll = orderBy(
        this.allPayrolls,
        ["appointment.start_time"],
        ["asc"]
      );
      tempPayroll.map(function (item) {
        if (
          item.appointment == null ||
          !vm.getAptProvider(item.appointment).user_id
        ) {
          return;
        }
        item.provider = vm.getAptProvider(item.appointment);
        item.expenses = vm.getAptExpenses(item.appointment);
        item.serviceNameLowered = (
          (item.service || {}).name || ""
        ).toLowerCase();
        item.providerNameLowered = (
          (item.provider || {}).name || ""
        ).toLowerCase();

        switch (item.service.unit) {
          case "FT":
          case "FLAT":
            item.flat = item.provider_rate;
            break;
          case "KM":
            item.mileage = item.qty * item.provider_rate;
            item.km = item.qty;
            break;
          default:
            item.hours = item.qty;
            item.hoursMoney = item.qty * item.provider_rate;
            break;
        }
        item.providerId = item.provider.user_id;
        item.hst = item.provider.hstNumber;

        item.provider_subTotal = item.qty * item.provider_rate;
        item.provider_total = item.qty * item.provider_rate;
        item.amt = item.qty * item.provider_rate;

        if (item.service.unit == "FT" || item.service.unit == "FLAT") {
          item.provider_total = item.provider_rate;
          item.provider_subTotal = item.provider_rate;
        }

        //hst calculations
        item.hst_calculation = 0;
        item.has_hst =
          item.provider.hstNumber != undefined && item.provider.hstNumber != "";

        if (
          item.has_hst &&
          item.service.taxable == true &&
          item.service.unit != "KM"
        ) {
          item.hst_calculation =
            parseFloat(item.provider_subTotal) * parseFloat(item.provider_tax);
        } else {
          item.provider_tax = 0;
          item.calculated_tax = 0;
        }

        item.totalWithHst =
          parseFloat(item.provider_subTotal) + parseFloat(item.hst_calculation);
        if (item.service.parent_service_id == null) {
          // add expenses for main service only.
          item.totalWithHst += parseFloat(
            (item.appointment || {}).expenses ? item.appointment.expenses : 0
          );
        }

        //calculate tax and total row
        item.calculated_tax =
          item.service.taxable == true && item.has_hst == true
            ? item.provider_tax * item.provider_subTotal
            : 0;

        item.amt = item.qty * item.provider_rate;

        if (item.service.unit == "FT" || item.service.unit == "FLAT") {
          item.amt = item.provider_rate;
        }
        if (
          item.appointment.expenses &&
          item.service.parent_service_id == null
        ) {
          item.provider_subTotal =
            item.appointment.expenses + item.provider_subTotal;
        }
      });

      tempPayroll = this.removeOldWrongData(tempPayroll);

      this.totalSubSum = this.calculateTotalSubSum(tempPayroll);
      this.totalSum = this.calculateTotalSum(tempPayroll);

      this.totalTax = this.calculateTotalTax(tempPayroll);
      this.totalHours = this.calculateTotalPerKey(tempPayroll, "hours");
      this.totalHoursMoney = this.calculateTotalPerKey(
        tempPayroll,
        "hoursMoney"
      );
      this.totalFlat = this.calculateTotalPerKey(tempPayroll, "flat");
      this.totalMileage = this.calculateTotalPerKey(tempPayroll, "mileage");
      this.totalKm = this.calculateTotalPerKey(tempPayroll, "km");
      this.totalExpenses = this.calculateTotalPerKey(tempPayroll, "expenses");
      this.groupedByProviderId = this.groupBy(tempPayroll, "providerId");
      if (this.uiFilter.optional_columns.group_by_criteria == 0) {
        this.allPayrolls = tempPayroll;
      } else if (this.uiFilter.optional_columns.group_by_criteria == 1) {
        // Group by service
        const tempPayroll2 = [];
        tempPayroll = orderBy(
          tempPayroll,
          [
            "serviceNameLowered",
            "appointment.start_time",
            "providerNameLowered",
          ],
          ["asc", "asc", "asc"]
        );
        let totalQty = 0;
        let totalAmount = 0;
        let total = 0;
        let subTotal = 0;
        let totalTax = 0;
        let totalWithHst = 0;
        let totalExpenses = 0;
        let totalHours = 0;
        let totalHoursMoney = 0;
        let totalMileage = 0;
        let totalKm = 0;
        let totalFlat = 0;
        tempPayroll.forEach((item, index) => {
          tempPayroll2.push(item);
          totalQty += parseFloat(item.qty || 0);
          totalHours += parseFloat(item.hours || 0);
          totalHoursMoney += parseFloat(item.hoursMoney || 0);
          totalMileage += parseFloat(item.mileage || 0);
          totalKm += parseFloat(item.km || 0);
          totalFlat += parseFloat(item.flat || 0);
          totalAmount += parseFloat(item.amt || 0);
          total += parseFloat(item.provider_total || 0);
          subTotal += parseFloat(item.provider_subTotal || 0);
          totalWithHst += parseFloat(item.totalWithHst || 0);
          totalExpenses += parseFloat((item.appointment || {}).expenses || 0);
          totalTax += parseFloat(item.calculated_tax || 0);
          if (
            index == tempPayroll.length - 1 ||
            item.patientId != tempPayroll[index + 1].patientId ||
            item.service.id != tempPayroll[index + 1].service.id
          ) {
            const newItem = {
              totalRow: true,
              totalService: `Total for ${
                item.service.parent_service_id ? "Sub Service" : "Service"
              } (${item.service.name})`,
              totalQty: totalQty,
              totalHours: totalHours,
              totalHoursMoney: totalHoursMoney,
              totalMileage: totalMileage,
              totalKm: totalKm,
              totalFlat: totalFlat,
              totalRate: "-----------",
              totalAmount: totalAmount,
              totalExpenses: totalExpenses,
              totalCalculatedTax: totalTax,
              total: total,
              subTotal: subTotal,
              provider_subTotal: subTotal,
              totalWithHst: totalWithHst,
              appointment: item.appointment,
              providerId: item.providerId,
              hst: item.hst,
            };
            tempPayroll2.push(newItem);
            totalQty =
              totalHours =
              totalHoursMoney =
              totalMileage =
              totalKm =
              totalFlat =
              totalAmount =
              totalTax =
              total =
              subTotal =
              totalWithHst =
              totalExpenses =
                0;
          }
        });
        const vm = this;
        this.allPayrolls = tempPayroll2.filter(
          (item) => item.appointment && vm.getAptProvider(item.appointment)
        );
      } else if (this.uiFilter.optional_columns.group_by_criteria == 2) {
        // Group by provider
        const tempPayroll2 = [];
        tempPayroll = orderBy(
          tempPayroll,
          ["providerNameLowered", "appointment.start_time"],
          ["asc", "asc"]
        );
        let totalQty = 0;
        let totalAmount = 0;
        let total = 0;
        let subTotal = 0;
        let totalTax = 0;
        let totalWithHst = 0;
        let totalExpenses = 0;
        let totalHours = 0;
        let totalHoursMoney = 0;
        let totalMileage = 0;
        let totalKm = 0;
        let totalFlat = 0;
        tempPayroll.forEach((item, index) => {
          tempPayroll2.push(item);
          totalQty += parseFloat(item.qty || 0);
          totalHours += parseFloat(item.hours || 0);
          totalHoursMoney += parseFloat(item.hoursMoney || 0);
          totalMileage += parseFloat(item.mileage || 0);
          totalKm += parseFloat(item.km || 0);
          totalFlat += parseFloat(item.flat || 0);
          totalAmount += parseFloat(item.amt || 0);
          total += parseFloat(item.provider_total || 0);
          subTotal += parseFloat(item.provider_subTotal || 0);
          totalWithHst += parseFloat(item.totalWithHst || 0);
          totalExpenses += parseFloat((item.appointment || {}).expenses || 0);
          totalTax += parseFloat(item.calculated_tax || 0);
          if (
            index == tempPayroll.length - 1 ||
            item.providerId != tempPayroll[index + 1].providerId
          ) {
            const newItem = {
              totalRow: true,
              totalService: `Total for ${(item.provider || {}).name}`,
              totalQty: totalQty,
              totalHours: totalHours,
              totalHoursMoney: totalHoursMoney,
              totalMileage: totalMileage,
              totalKm: totalKm,
              totalFlat: totalFlat,
              totalRate: "-----------",
              totalAmount: totalAmount,
              totalExpenses: totalExpenses,
              totalCalculatedTax: totalTax,
              total: total,
              subTotal: subTotal,
              provider_subTotal: subTotal,
              totalWithHst: totalWithHst,
              appointment: item.appointment,
              providerId: item.providerId,
              hst: item.hst,
            };
            tempPayroll2.push(newItem);
            totalQty =
              totalHours =
              totalHoursMoney =
              totalMileage =
              totalKm =
              totalFlat =
              totalAmount =
              totalTax =
              total =
              subTotal =
              totalWithHst =
              totalExpenses =
                0;
          }
        });
        const vm = this;
        this.allPayrolls = tempPayroll2.filter(
          (item) => item.appointment && vm.getAptProvider(item.appointment)
        );
      }
    },
    getReportByFilterHelper: function (excelOnly = false) {
      this.submittedFilter = JSON.parse(JSON.stringify(this.filter));
      this.excelOnly = excelOnly;
      return this.getReportByFilter();
    },
    generateExcelOnly: function () {
      this.reportIsPending = this.reportIsPending || this.busy;
      this.isCancelled = this.isCancelled || this.busy;
      return this.getReportByFilterHelper(true).then(() => {
        return this.loadRestOfData();
      });
    },
    getReportByFilter: function (currentPage = 0) {
      this.providersNamesInReport = [];
      if (
        this.submittedFilter.dateRangeEnabled &&
        (this.filter.filterIsDirty || currentPage > 0)
      ) {
        return this.filterByDate(currentPage).then(() => {
          this.handleReportsToDisplay();
          if (!this.isCancelled) {
            this.showReportClicked = true;
          }
          return Promise.resolve();
        });
      } else {
        const showBillable = this.submittedFilter.non_billable;
        let tempPayroll = JSON.parse(
          JSON.stringify(
            this.payrolls.filter(
              (item) =>
                item.appointment &&
                (this.submittedFilter.non_billable == -1 ||
                  item.appointment.billable == showBillable)
            )
          )
        );
        this.allPayrolls = this.removeOldWrongData(tempPayroll);
        this.handleReportsToDisplay();
        return Promise.resolve();
      }
    },
    handleClassProviderFilters: function () {
      let vm = this;
      if (
        this.submittedFilter.providers != "" ||
        this.submittedFilter.classification != "" ||
        this.submittedFilter.providersManager != ""
      ) {
        if (this.submittedFilter.providers != "") {
          this.providersNamesInReport = [];
          this.providersAdminsList.forEach(function (item) {
            if (item.user_id == vm.submittedFilter.providers) {
              vm.providersNamesInReport.push(item.name);
            }
          });
          this.allPayrolls = this.allPayrolls.filter(
            (item) =>
              item.appointment != null &&
              vm.getAptProvider(item.appointment) &&
              vm.getAptProvider(item.appointment).user_id ==
                this.submittedFilter.providers
          );
        } else if (this.submittedFilter.providersManager != "") {
          this.providersNamesInReport = [];
          this.providersAdminsList.forEach(function (item) {
            if (
              vm.providerHasManager(
                item.user_id,
                vm.submittedFilter.providersManager
              )
            ) {
              vm.providersNamesInReport.push(item.name);
            }
          });
          this.allPayrolls = this.allPayrolls.filter(
            (item) =>
              item.appointment != null &&
              vm.getAptProvider(item.appointment) &&
              vm.providerHasManager(
                vm.getAptProvider(item.appointment).user_id,
                vm.submittedFilter.providersManager
              )
          );
        }

        if (this.submittedFilter.classification != "") {
          let selectedClassificationsIds =
            this.submittedFilter.classification.map((item) => item.id);
          let allPayrollWithClass = [];
          let vm2 = this;
          this.allPayrolls.forEach(function (item) {
            let vm3 = vm2;
            selectedClassificationsIds.forEach(function (selectedClassID) {
              const user = vm3.getAptProvider(item.appointment || {});
              if (
                item.appointment &&
                (user.classifications_ids || []).includes(selectedClassID) &&
                !allPayrollWithClass.includes(item)
              ) {
                allPayrollWithClass.push(item);
              }
            });
          });
          this.allPayrolls = allPayrollWithClass;
        }
      } else {
        this.providersNamesInReport = [];
        this.providersAdminsList.forEach(function (item) {
          vm.providersNamesInReport.push(item.name);
        });
      }
    },
    handleReportsToDisplay: function () {
      this.handleClassProviderFilters();
      this.groupProvidersForReport();
      if (!this.excelOnly) {
        this.shouldViewReport = true;
      }
      this.handleExcelData();
    },
    handleExcelData: function () {
      if (!this.printSettings.exportTotalOnly) {
        this.printSettings.separateRate = false;
      }
      if (!this.shouldViewReport && !this.excelOnly) {
        return;
      }
      if (this.uiFilter.optional_columns.group_by_criteria == 2) {
        const printExcelDataTemp = [];
        const excelPayroll = JSON.parse(JSON.stringify(this.allPayrolls));
        let providerPayrollRate = {};
        excelPayroll.forEach((item) => {
          if (item.totalRow == false || item.totalRow == undefined) {
            if (!this.printSettings.exportTotalOnly) {
              printExcelDataTemp.push(item);
            } else if (this.printSettings.separateRate) {
              let payrollItem = providerPayrollRate[item.provider_rate];
              if (!payrollItem) {
                item.appointment = `${
                  this.getAptProvider(item.appointment).name || ""
                }`;
                item.service = "";
                item.hours = item.hours || 0;
                item.hoursMoney = item.hoursMoney || 0;
                item.mileage = item.mileage || 0;
                item.km = item.km || 0;
                item.flat = item.flat || 0;
                providerPayrollRate[item.provider_rate] = item;
              } else {
                payrollItem.qty += parseFloat(item.qty || 0);
                payrollItem.hours += parseFloat(item.hours || 0);
                payrollItem.hoursMoney += parseFloat(item.hoursMoney || 0);
                payrollItem.mileage += parseFloat(item.mileage || 0);
                payrollItem.km += parseFloat(item.km || 0);
                payrollItem.flat += parseFloat(item.flat || 0);
                payrollItem.amt += parseFloat(item.amt || 0);
                payrollItem.provider_subTotal += parseFloat(
                  item.provider_subTotal || 0
                );
                payrollItem.calculated_tax += parseFloat(
                  item.calculated_tax || 0
                );
                payrollItem.totalWithHst += parseFloat(item.totalWithHst || 0);
                payrollItem.expenses += parseFloat(item.expenses || 0);
              }
            }
          } else {
            Object.keys(providerPayrollRate).forEach((key) => {
              printExcelDataTemp.push(providerPayrollRate[key]);
            });
            providerPayrollRate = {};
            printExcelDataTemp.push({
              qty: item.totalQty,
              hours: item.totalHours,
              hoursMoney: item.totalHoursMoney,
              mileage: item.totalMileage,
              km: item.totalKm,
              flat: item.totalFlat,
              amt: item.totalAmount,
              provider_subTotal: item.provider_subTotal,
              calculated_tax: item.totalCalculatedTax,
              totalWithHst: item.totalWithHst,
              provider_rate: item.totalRate,
              appointment:
                this.printSettings.exportTotalOnly &&
                !this.printSettings.separateRate
                  ? item.totalService.replace("Total for ", "")
                  : item.totalService,
              providerId:
                this.printSettings.exportTotalOnly &&
                !this.printSettings.separateRate
                  ? item.providerId
                  : "-----------",
              hst:
                this.printSettings.exportTotalOnly &&
                !this.printSettings.separateRate
                  ? item.hst
                  : "-----------",
              expenses: item.totalExpenses,
              service: "total",
            });
            if (!this.printSettings.exportTotalOnly) {
              printExcelDataTemp.push({
                qty: "-----------",
                flat: "-----------",
                hours: "-----------",
                hoursMoney: "-----------",
                mileage: "-----------",
                km: "-----------",
                amt: "-----------",
                provider_subTotal: "-----------",
                calculated_tax: "-----------",
                totalWithHst: "-----------",
                provider_rate: "-----------",
                appointment: "-----------",
                providerId: "-----------",
                hst: "-----------",
                service: "-----------",
                expenses: "-----------",
              });
            }
          }
        });
        this.printExcelData = printExcelDataTemp;
        const originalRecords = this.allPayrolls.filter(
          (item) => item.totalRow == false || item.totalRow == undefined
        );
        let totalQty = 0;
        let totalHours = 0;
        let totalHoursMoney = 0;
        let totalMileage = 0;
        let totalKm = 0;
        let totalFlat = 0;
        let totalAmt = 0;
        let totalTax = 0;
        let totalSubTotal = 0;
        let totalTotal = 0;
        let totalExpenses = 0;

        originalRecords.forEach((item) => {
          totalQty += parseFloat(item.qty || 0);
          totalHours += parseFloat(item.hours || 0);
          totalHoursMoney += parseFloat(item.hoursMoney || 0);
          totalMileage += parseFloat(item.mileage || 0);
          totalKm += parseFloat(item.km || 0);
          totalFlat += parseFloat(item.flat || 0);
          totalAmt += parseFloat(item.amt || 0);
          totalTotal += parseFloat(item.totalWithHst || 0);
          totalSubTotal += parseFloat(item.provider_subTotal || 0);
          totalTax += parseFloat(item.calculated_tax || 0);
          totalExpenses += parseFloat(item.expenses || 0);
        });

        this.printExcelData.push({
          qty: "-----------",
          hours: "-----------",
          hoursMoney: "-----------",
          flat: "-----------",
          mileage: "-----------",
          km: "-----------",
          amt: "-----------",
          provider_subTotal: "-----------",
          calculated_tax: "-----------",
          totalWithHst: "-----------",
          provider_rate: "-----------",
          appointment: "-----------",
          providerId: "-----------",
          hst: "-----------",
          service: "-----------",
          expenses: "-----------",
        });
        this.printExcelData.push({
          qty: totalQty,
          hours: totalHours,
          hoursMoney: totalHoursMoney,
          mileage: totalMileage,
          km: totalKm,
          flat: totalFlat,
          amt: totalAmt,
          provider_subTotal: totalSubTotal,
          calculated_tax: totalTax,
          totalWithHst: totalTotal,
          provider_rate: "-----------",
          appointment: "total",
          providerId: "-----------",
          hst: "-----------",
          expenses: totalExpenses,
          service: "total",
        });
      } else {
        this.printExcelData = this.allPayrolls.filter(
          (item) => item.totalRow == false || item.totalRow == undefined
        );
        let totalQty = 0;
        let totalHours = 0;
        let totalHoursMoney = 0;
        let totalMileage = 0;
        let totalKm = 0;
        let totalFlat = 0;
        let totalAmt = 0;
        let totalTax = 0;
        let totalSubTotal = 0;
        let totalTotal = 0;
        let totalExpenses = 0;

        this.printExcelData.forEach((item) => {
          totalQty += parseFloat(item.qty || 0);
          totalHours += parseFloat(item.hours || 0);
          totalHoursMoney += parseFloat(item.hoursMoney || 0);
          totalMileage += parseFloat(item.mileage || 0);
          totalKm += parseFloat(item.km || 0);
          totalFlat += parseFloat(item.flat || 0);
          totalAmt += parseFloat(item.amt || 0);
          totalTotal += parseFloat(item.totalWithHst || 0);
          totalSubTotal += parseFloat(item.provider_subTotal || 0);
          totalTax += parseFloat(item.calculated_tax || 0);
          totalExpenses += parseFloat(item.expenses || 0);
        });

        if (
          this.printSettings.exportTotalOnly &&
          this.uiFilter.optional_columns.group_by_criteria == 1
        ) {
          const printExcelDataTemp = [];
          const tempData = this.allPayrolls.filter((item) => item.totalRow);
          tempData.forEach((item) => {
            printExcelDataTemp.push({
              qty: item.totalQty,
              hours: item.totalHours,
              hoursMoney: item.totalHoursMoney,
              mileage: item.totalMileage,
              km: item.totalKm,
              flat: item.totalFlat,
              amt: item.totalAmount,
              provider_subTotal: item.provider_subTotal,
              calculated_tax: item.totalCalculatedTax,
              totalWithHst: item.totalWithHst,
              rate: item.provider_rate,
              appointment: "-----------",
              providerId: "-----------",
              hst: "-----------",
              expenses: item.totalExpenses,
              service: {
                name: item.totalService.replace("Total for ", ""),
              },
            });
            this.printExcelData = printExcelDataTemp;
          });
        }

        this.printExcelData.push({
          qty: "-----------",
          hours: "-----------",
          hoursMoney: "-----------",
          flat: "-----------",
          mileage: "-----------",
          km: "-----------",
          amt: "-----------",
          provider_subTotal: "-----------",
          calculated_tax: "-----------",
          totalWithHst: "-----------",
          provider_rate: "-----------",
          appointment: "-----------",
          providerId: "-----------",
          hst: "-----------",
          service: "-----------",
          expenses: "-----------",
        });
        this.printExcelData.push({
          qty: totalQty,
          hours: totalHours,
          hoursMoney: totalHoursMoney,
          mileage: totalMileage,
          km: totalKm,
          flat: totalFlat,
          amt: totalAmt,
          provider_subTotal: totalSubTotal,
          calculated_tax: totalTax,
          totalWithHst: totalTotal,
          provider_rate: "-----------",
          appointment: "total",
          providerId: "-----------",
          hst: "-----------",
          expenses: totalExpenses,
          service: "total",
        });
      }
    },
    groupBy: function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    prettyDate: function (date, format = null) {
      if (date == undefined || date == null) {
        return;
      }
      if (!format) {
        return dateFormat(date, "human");
      } else {
        return dateFormat(date, format);
      }
    },
    handlePrintButton: function () {
      if (this.busy) {
        return;
      }
      this.loadRestOfData().then(() => {
        this.printClicked = false;
        window.print();
      });
    },
    handleSavePdfButton: function () {
      if (this.busy) {
        return;
      }
      if (
        !this.allPayrolls.length ||
        !this.shouldViewReport ||
        this.reportsLoading
      ) {
        return Swal.fire({
          title: "",
          html: "Nothing to save",
          icon: "warning",
        });
      }
      return this.loadRestOfData().then(() => {
        try {
          this.processing = true;
          this.processingText = "<h3>Preparing PDF...</h3>";
          this.$forceUpdate();
          this.$refs.payrollContainerTable.style.width = "min-content";
          this.$refs.payrollContainerOuterTable.style.width = "min-content";
        } catch (_) {
          this.fireError();
          this.$forceUpdate();
          return;
        }
        this.$refs.payrollContainerTable.style.width = "min-content";
        this.$refs.payrollContainerOuterTable.style.width = "min-content";
        setTimeout(() => {
          html2pdf(this.$refs.payrollContainerOuterTable, {
            margin: 0,
            filename: "CRM-Payroll-Report.pdf",
            image: { type: "jpeg", quality: 1 },
            html2canvas: {
              scale: 4,
              allowTaint: true,
              useCORS: true,
              letterRendering: true,
              width: this.$refs.payrollContainerTable.clientWidth,
            },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          })
            .then(() => {
              this.$refs.payrollContainerTable.style.width = "";
              this.$refs.payrollContainerOuterTable.style.width = "";
              this.fireError();
              this.$forceUpdate();
            })
            .catch(() => {
              this.$refs.payrollContainerTable.style.width = "";
              this.$refs.payrollContainerOuterTable.style.width = "";
              this.fireError();
              this.$forceUpdate();
              Swal.fire({
                title: "Error",
                html: "Something went wrong...",
                icon: "error",
              });
            });
        }, 0);
      });
    },
    sortAlphabetically(arrayInput) {
      const arraySrc = new Array(...arrayInput);
      const result = arraySrc.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      return result;
    },
    getAptProvider: function (item) {
      let tempItem;
      if (item.provider) {
        tempItem = item.provider;
      } else {
        tempItem = item.user_1 || {};
        tempItem.user_id = tempItem.id;
      }
      return tempItem;
    },
    getAptPatient: function (item) {
      let tempItem;
      if (item.patient) {
        tempItem = item.patient;
      } else {
        tempItem = item.user_2 || {};
        tempItem.user_id = tempItem.id;
      }
      return tempItem;
    },
    getAptExpenses: function (item) {
      if (item && item.expenses) {
        return item.expenses;
      }
      return 0;
    },
    providerHasManager: function (id, managerId) {
      if (this.invoiceProviders.length) {
        const matchProvider = this.invoiceProviders.filter(
          (item) => item.user_id == id
        )[0];
        if (matchProvider && matchProvider.managers) {
          return matchProvider.managers.includes(managerId);
        }
      }

      return false;
    },
    loadMore() {
      if (this.payrolls.length < this.total) {
        this.busy = true;
        this.getReportByFilter(this.current + 1).then(() => {
          this.busy = false;
        });
      }
    },
    loadRestOfData(fromViewReport = false) {
      return this.loadRestOfDataAsync(fromViewReport).then(() => {
        this.isCancelled = false;
      });
    },
    async loadRestOfDataAsync(fromViewReport = false) {
      const leftCount = this.total - this.payrolls.length;
      if (leftCount == 0) {
        return Promise.resolve();
      } else {
        let numberOfRequests = parseInt(leftCount / this.limit);
        if (leftCount % this.limit > 0) {
          numberOfRequests++;
        }
        this.busy = true;
        if (!fromViewReport) {
          this.processing = true;
          this.setProgressMessage(
            "Getting all data...<br>This may take few moments. Please don't refresh the page."
          );
        }
        this.percent = 0;
        const requestEachTime = 5;
        let numberOfRequestGroup = parseInt(numberOfRequests / requestEachTime);
        numberOfRequestGroup += numberOfRequests % requestEachTime ? 1 : 0;
        let currentPage = this.current + 1;
        for (let i = 1; i <= numberOfRequestGroup; i++) {
          if (this._isDestroyed || this.isCancelled) {
            if (this.isCancelled) {
              this.setPayrollCurrentPage(currentPage - 1);
            }
            break;
          }
          await new Promise((resolve) => {
            const invoicePromisees = [];
            for (
              let j = 0;
              j < requestEachTime &&
              j + (i - 1) * requestEachTime < numberOfRequests;
              j++
            ) {
              invoicePromisees.push(this.getReportByFilter(currentPage + j));
            }
            currentPage += requestEachTime;
            Promise.all(invoicePromisees).then(() => {
              this.percent = +parseFloat(
                ((i * requestEachTime * this.limit) / this.total) * 100
              ).toFixed(2);
              if (!fromViewReport) {
                this.setProgressMessage(
                  "Getting all data...<br>This may take few moments. Please don't refresh the page."
                );
              }
              return resolve();
            });
          });
        }
        if (this.payrolls.length == this.total) {
          this.percent = 0;
        }
        this.busy = false;
        this.processing = false;
        return Promise.resolve();
      }
    },
    setProgressMessage: function (message) {
      const percentMessage = `<h1 style="color: mediumspringgreen">${this.percent} %</h1>`;
      this.processingText = `${
        this.percent > -1 ? percentMessage : ""
      }${message}`;
    },
    fireError: function () {
      this.processing = false;
      this.processingText = "";
      this.percent = -1;
    },
  },
};
</script>

<style scoped lang="scss">
@media print {
  @page {
    size: auto;
  }
  body {
    background-color: #fff !important;
  }
  .reportTable {
    table-layout: fixed;
  }
  .printPage {
    page-break-after: always;
    margin-top: 120px;
  } /* printPage-break-after works, as well */
}

.border-bottom {
  border-color: var(--secondary-color) !important;
}
.like-header td {
  background-color: rgba(37, 37, 37, 0.4) !important;
  color: white !important;
  font-weight: 900 !important;
  font-size: 0.8125rem !important;
}
.loader {
  top: 0;
  left: 0;
  right: 0;
  border: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.8);
  color: whitesmoke;
  flex-direction: column;
}
.mobile-tablet-loadmore {
  background-color: #6e84a3 !important;
  border-color: #6e84a3;
  color: #fff !important;
}
.action-indicator {
  width: 12px;
  height: 12px;
  padding: 0;
  position: absolute;
  top: 0px;
  right: 1px;
}
.pdfCompanyLogo {
  margin: 50px auto 20px auto;
  text-align: center;
  img {
    height: 200px;
  }
}
.reportCompanyLogo {
  margin: 50px auto;
  text-align: center;
  img {
    height: 200px;
  }
}
.signitureReport img {
  height: 50px;
  width: 200px;
}
.disabled {
  pointer-events: none;
}
:disabled {
  pointer-events: none;
}
.print-menu {
  max-height: 350px;
  overflow-y: auto;
  z-index: 1000;
  .dropdown-item.disabled {
    opacity: 0.6;
    &,
    input,
    label {
      pointer-events: none;
      &::before,
      &::after {
        pointer-events: none;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@media (max-width: 767px) {
  .report-actions-container {
    flex-direction: column-reverse;

    .report-actions-inner-container {
      margin-top: 36px;

      .view-report {
        min-width: 50%;
      }
    }
  }
}
.multiselect {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px;
  border-radius: 36px;
}
.py-8-px {
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>
<style lang="scss">
.group-by-select {
  select {
    width: auto;
    padding-left: 8px;
    padding-right: 8px !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px;
  }
  label {
    flex: 0 0 auto !important;
    width: auto !important;
    max-width: 100% !important;
  }
}
.excel-control-group {
  .excel-menu {
    left: -42px !important;
  }
}
.class-ddl,
.service-ddl {
  .multiselect__tags {
    min-height: 40.5px;
    padding-top: 6px;
  }
}
</style>
